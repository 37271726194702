body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --chattitle: #85f6e4;
}

/* ------------------ ------------------ */
.vuho-activity {
    text-align: center;
    font-size: 75%;
    padding-bottom: 25px;
}

.vuho-activity span {
    display: block;
    color: #797979;
    /*margin-right: 20px;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.form-control {
    background-color: #102625;
    border-color: #183433;
    font-weight: 300;
}

.page-link {
    cursor: pointer;
}

/* ------------------ ------------------ */
.fqview-tabs .nav-link {
    color: white !important;
}

/* ------------------ ------------------ */
.fq-table__search {
    padding: 0 4px;
    display: flex;
    flex-direction: row;
}

.fq-table__search-fq {
    margin-right: 15px;
}
.fq-table__search-names {
    flex: 1;
}
.fq-table__noresults {
    text-align: center;
    padding: 75px 25px;
    background: rgba(0,0,0,.2)
}

.fq-table-table {
    margin-top: 3px;
}
.fq-table-table td span {
    cursor: pointer;
}

.fq-table-panel {
    height: 26px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1f1c1a;
    color: white;
}

.fq-table-container {
    padding-bottom: 50px;
    position: relative;
}

.fq-table-container input {
    margin-bottom: 4px;
}


.fq-tile {
    padding-left: 15px;
    padding-right: 15px;
    line-height: 25px;
    display: inline-block;
    border: 1px solid #ccc;
}

.fq-tile:hover {
    background-color: #ccc;
    cursor: pointer;
}

.fq-navigate-cell {
    width: 200px;
}
.fq-navigate-cell i {
    font-style: normal;
    font-size: 75%;
    display: block;
    font-weight: lighter;
    color: var(--chattitle);
}

.fq-navigate-cell span,
.fq-cross-link {
    border-bottom: 1px dotted white;

}

.fq-cross-link:hover {
    text-decoration: none;
}

.fq-navigate-cell {
    position: relative;
    padding-right: 34px !important;
}
.fq-navigate-cell__search {
    padding-right: 0 !important;
}

.fq-navigate-cell:hover {
    background-color: #003d3d;
    color: white;
    cursor: pointer;
}

.fq-navigate-cell:hover span {
    color: white;
}

.fq-navigate-cell__button {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 30px;
    /*border: 1px solid var(--chattitle);*/
    /*background: #1f1c1a;*/
}

.fq-navigate-cell__button:hover {
    background: var(--chattitle);
}

.fq-navigate-cell__button:active {
    opacity: 0.7
}

.fq-cross-link-container {
    display: block;
    margin-right: 38px;
}

.fq-cross-link-container:hover {
    /*background-color: #000;*/
}

.fq-cross-link {
    margin-right: 5px
}

.fq-cross-link:hover {
    background-color: #003d3d;
    color: white;
    cursor: pointer;
}

.fq-cross-links i {
    color: var(--chattitle);
}


.fq-names-textarea {
    width: 100%;
}

.auth {
    text-align: right;
    line-height: 37px;
    color: white;
}

.auth__link {
    cursor: pointer;
    color: white
}

.auth__logout {
    cursor: pointer;
    color: white
}

.fq-wrapper {
    position: relative;
}

.fq-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #1c3035;
    z-index: 10;
    padding: 1px 10px;
    /*min-width: 520px;*/
    display: flex;
}

.fq-nav__tabs {
}

.fq-nav__auth {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
}

.fq-outlet {
    margin-top: 44px;
}

.fq-wrapper-iniframe .fq-outlet {
    margin-top: 0;
}

/* ------------------ ------------------ */
.fqview-topcontainer {
    display: flex;
}

.fqview-topcontainer__name {
    /*display: inline-block;*/
}

.fqview-topcontainer__name h1 {
    /*display: inline-block;*/
}

.fqview-topcontainer__chattitle {
    /*display: inline-block;*/
    height: 48px;
    flex: 1;
    padding-top: 5px;
    padding-left: 20px;
}

.fqview-topcontainer__chattitle input {
    width: 100%;
    font-size: 120%;
    color: var(--chattitle);
}

.fqview-chattitle__text {
    line-height: 40px;
}

.fqview-tabcontainer {
    padding-top: 10px;
}

.fqview-container {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 400px;
}

/* ------------------ ------------------ */
.fq-names-cell {
    line-height: 1.2;
}

.fq-names-cell span {
}

/* ------------------ ------------------ */
.daystory {
    margin-bottom: 20px;
}

.daystory__value {
    margin-left: 20px;
}

.daystory__value .form-control {
    min-height: 49px;
}

.daystory__date {
    width: 150px;
}

/* ------------------ ------------------ */
.maptab {
    /*padding: 0 7px 25px 7px;*/
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.maptab__form {
    margin-bottom: 5px;
    padding: 0 10px;
}

.maptab__map {
    flex: 1;
    /*height: 500px;*/
}

.maptab__conversion {
    display: flex;
    flex-direction: column;
}
.maptab__conversion-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.maptab__form-toprow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.maptab__conversion_proj {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-right: 12px;
}
.maptab__conversion_proj-text {
    align-self: flex-end;
    padding-bottom: 10px;
    flex-direction: column;
    width: 50px;
}
.maptab__conversion_proj-type {
    /*padding-top: 5px;*/
}
.maptab__conversion_proj-copy {
    width: 20px;
    background-color: #1c3035;
    margin-right: 5px;
}
.maptab__conversion_proj-copy:hover {
    background-color: var(--chattitle);
    cursor: pointer;
}

.maptab__conversion_field {
    margin-right: 5px;
}
.maptab__conversion_proj-wgs84str {
    line-height: 23px;
    font-size: 80%;
}
.map, .map iframe {
    min-height: 200px;
    height: 100%;
}
/* ------------------ ------------------ */
.fqnodata {

}
.fqnodata .btn-warning {
    color: white;
}
.fq-table__searches th,.fq-table__searches td{padding: 0;}
.fq-table__searches .MuiFormControl-root {margin: 0; width: 100%}
/* ------------------ ------------------ */
.auth__email {
    font-size: 11px;
}
.auth__logout {
    border-bottom: 1px dotted white;
}
/* ------------------ ------------------ */
.doc {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 25;
}
/* ------------------ ------------------- */

.copy-feedback {
    width: max-content;
    position: absolute;
    white-space: nowrap;
    top: -28px;
    left: 0;
    padding: 2px 4px;
    color: white;
    background-color: #183433;
    border-radius: 4px;
    font-size: 12px;
    z-index: 999999999999;
}